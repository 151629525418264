<template>
	<div>
		<div class="ft20 cl-main ftw600">站点分类</div>

		<div class="mt20">
			<div class="bg-w">
				<div class="pd30">
					<div>
						<a-button type="primary" icon="plus" @click="addTypeAct()">添加分类</a-button>
					</div>
					<div class="form-search-box ">
						<a-form layout="inline">
							<a-form-item label="分类名称">
								<a-input v-model="search.name" placeholder="请输入分类名称"></a-input>
							</a-form-item>

							<a-form-item>
								<a-button @click="searchAct" type="primary">查询</a-button>
								<a-button @click="cancelAct" class="ml10">取消</a-button>
							</a-form-item>
						</a-form>
					</div>

					<div class="">
						<div class="wxb-table-gray">
							<a-table rowKey="site_type_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
											 :data-source="datas" :loading="loading">

								<div slot="icon" slot-scope="icon,record" class="project-type-icon">
									<img v-if="icon != null" :src="icon"/>
								</div>

								<template slot="action" slot-scope="record,index">
									<div class="flex center">
										<a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
											<a-menu slot="overlay">
												<a-menu-item>
													<a class="menu-act" href="javascript:;" @click="editTypeAct(record)">
														<i class="iconfont ft14 iconedit"></i>
														<span class="ml10">编辑</span>
													</a>
												</a-menu-item>
												<a-menu-item>
													<a class="menu-act" href="javascript:;" @click="delTypeAct(record)">
														<i class="iconfont ft14 icondelete"></i>
														<span class="ml10">删除</span>
													</a>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
								</template>
							</a-table>
						</div>
					</div>

					<div v-if="editTypeVisible">
						<edit-type :visible="editTypeVisible" :site_type_id="site_type_id" @cancel="cancelEditType" @ok="okEditType"></edit-type>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import {listMixin} from '@/common/mixin/list';
import editType from './components/edit.vue';
export default{
	mixins: [listMixin],
	components:{
		editType,
	},
	data(){
		return{
			loading: false,
			editTypeVisible:false,
			pagination: {
				current: 1,
				pageSize: 10, //每页中显示10条数据
				total: 0,
				showTotal:total=>`共${total} 条数据`
			},
			site_type_id:0,
			search:{
				name:'',
			},
			columns: [
				{title: '分类名称',dataIndex: 'name',align: 'left',ellipsis: true},
				{title: '分类图标',dataIndex: 'icon',align: 'left',scopedSlots: {customRender: 'icon'}},
				{title: '站点数',dataIndex: 'site_count',align: 'right',ellipsis: true},
				{title: '排序',dataIndex: 'sort',align: 'right',ellipsis: true},
				// {title: '创建时间',dataIndex: 'create_time',align: 'left',ellipsis: true},
				{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
			],
			datas: [],
		}
	},
	methods:{
		getLists(){
			if(this.loading==true) return;
			this.loading=true;
			this.$http.api('platform/site/getSiteTypeList',{
				limit:this.pagination.pageSize,
				page:this.pagination.current,
				name:this.search.name,
			}).then(res=>{
				this.pagination.total=res.total;
				this.datas=res.list;
				this.loading=false;
			}).catch(res=>{
				this.loading=false;
			})
		},

		delTypeAct(record){
			this.$confirm({
				title:'确认删除这个分类吗？',
				okText:"确定",
				okType:"danger",
				cancelText:"取消",
				onOk : ()=>{
					return new Promise((resolve,reject)=>{
						this.$http.api('platform/site/delSiteType',{
							site_type_id:record.site_type_id,
						}).then(res=>{
							this.$message.success('删除成功',1,()=>{
								this.getLists();
							});
						}).catch(res=>{
							console.log(res);
						}).finally(()=>{
							resolve();
						})
					})
				}
			})
		},

		addTypeAct(){
			this.site_type_id=0;
			this.editTypeVisible=true;
		},
		editTypeAct(record){
			this.site_type_id=record.site_type_id;
			this.editTypeVisible=true;
		},
		cancelEditType(){
			this.editTypeVisible=false;
		},
		okEditType(){
			this.editTypeVisible=false;
			this.getLists();
		},
		handleTableChange(pagination, filters, sorter) {
			this.pagination.current = pagination.current;
			this.getLists();
		},
	}
}
</script>

<style>
.projetc-index-cover-img{
	width: 80px;
	height: 60px;
}
.project-type-icon img{
	width:40px;
	height:40px;
}
</style>
